.footer-wrapper {
  background-color: $footer-bg;
  min-height: $footer-height;
  overflow: hidden;
  position: relative;
  z-index: 10;

  @media screen and (min-width: map-get($grid-breakpoints, xl)) {
    & {
      padding-left: $navigation-width;
    }
  }

  .footer-nav {
    color: $footer-link-color;
    margin-bottom: 0;
    padding-left: 0;
    grid-gap: 1.156rem;

    li {
      list-style: none;
    }

    a {
      color: $footer-link-color;
      text-decoration: none;
      display: block;
      transform: translateY(0);
      transition: color 0.25s ease, transform 0.25s ease;

      .icon {
        line-height: 0;
        padding-top: 1rem;
      }

      @media (hover: hover) {
        &:hover {
          color: $footer-link-color-hover;
          transform: translateY(-2px);
        }
      }
    }

    .footer-nav-social {
      padding-left: 0;
    }
  }

    .footer-nav {
      height: $footer-height;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        & {
          flex-wrap: wrap;
          height: auto;
          padding: map-get($spacers, 4);
        }
      }
    }

    .footer-nav-social {
      display: flex;
      justify-content: center;
      grid-gap: 1.156rem;

      @media screen and (max-width: map-get($grid-breakpoints, sm)) {
        & {
          width: 100%;
        }
      }
    }
}