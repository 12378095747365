.intro {
  background-color: $card-bg;
  backdrop-filter: blur(10px);
  box-shadow: $card-box-shadow;
  padding: map-get($spacers, 6) map-get($spacers, 4) map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
  border-radius: $border-radius;
  animation: fadeIn .5s linear;
  position: relative;

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    & {
      padding: map-get($spacers, 6);
      margin-bottom: map-get($spacers, 6);
    }
  }

  .intro-inner {
    opacity: 0;
  }

  .intro-text {
    color: $intro-text-color;
  }

  &.loading-true {
    .intro-inner {
      animation: fadeBlurOut 1s ease forwards;
    }

  }

  &.loading-false {
    .intro-inner {
      animation: fadeBlurIn .5s ease forwards;
    }
  }
}

.audio-button {
  position: absolute;
  right: 1rem;
  top: 1rem;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
  }
}