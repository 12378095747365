.text-block {
  .text-block-inner {
    padding: map-get($spacers, 6);
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: map-get($grid-breakpoints, sm)) {
      & {
        padding: map-get($spacers, 4);
      }
    }

    .icon {
      font-size: 4rem;
      color: $light;
      margin-bottom: map-get($spacers, 3);
    }
  }
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}