.trends {
  margin-bottom: map-get($spacers, 6);
  color: $base-font-color;

  .trends-intro {
    margin-bottom: map-get($spacers, 6);
  }

  .materials .card {
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      &:nth-child(3n+1) {
        width: 100%;
        display: flex;
        align-items: center;
        grid-column: 1/3;

        & > div {
          width: 50%;
        }

        .material-img {

          img {
            height: 90%;
            object-fit: contain;
            object-position: top left;
          }
        }
      }
    }
  }
}