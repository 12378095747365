.stilfinder {
  .quiz,
  .stilfinder-result {
    color: $light;
  }

  .question-item {
    display: none;
    opacity: 0;
    transition: opacity 0.25s ease;

    &.show {
      display: block;
      opacity: 1;
    }
  }

  .answers {
    display: grid;
    grid-gap: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 4);

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      & {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .answer {
    display: flex;
    align-items: center;
    background-color: $primary;
    padding: map-get($spacers, 3);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    color: $light;
    font-size: $font-size-base;
    transition: filter 0.25s ease;

    @media (hover: hover) {
      &:hover {
        filter: brightness(85%);
        cursor: pointer;
      }
    }

    input {
      appearance: none;
      pointer-events: none;

      &:before {
        content: "";
        display: inline-block;
        margin-right: map-get($spacers, 1);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid $quiz-select-color;
        transition: background-color 0.25s ease;
        transform: translateY(3px);
      }

      &:checked {
        &:before {
          background-color: $quiz-select-color;
        }
      }
    }
  }

  .answer-text {
    pointer-events: none;
  }

  .quiz-footer {
    font-style: italic;
    color: $quiz-footer-color;

    .back-link {
      font-style: normal;
      color: $quiz-footer-color;
    }
  }
}