.search-form {
  color: $base-font-color;
  animation: fadeIn 2s ease;
  padding: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 8);
  border-radius: $border-radius;
  background-color: $card-bg;
  backdrop-filter: blur(10px);
  box-shadow: $card-box-shadow;
  position: relative;
  z-index: 40;

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    & {
      padding: map-get($spacers, 4) map-get($spacers, 6);
    }
  }

  .search-form-inner {
    align-items: center;
    justify-content: space-between;
  }

  .search-form-input-group {
    box-shadow: $input-group-shadow;
    border-radius: $border-radius*2;

    .input-group-text {
      background-color: $input-bg;
      border-radius: $border-radius*2;
      border: none;
      color: $input-color;
      font-size: $font-size-base;
      text-align: center;
    }

    .search-form-input {
      color: $input-color;
      font-size: $font-size-base;
      border-radius: $border-radius*2;
      height: 45px;
      border: none;
      outline: none;
      box-shadow: none;
      background-color: $input-bg;
    }
  }


  .form-select {
    height: 45px;

    option {
      background-color: $primary;
    }
  }
}