.theme-select {
  width: 100%;
  left: 0;
  top: calc(100% + map-get($spacers, 3));
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background-color: $input-bg;
  position: absolute;
  z-index: 40;
  transform-origin: left top;
  transform: scaleY(0);
  opacity: 0;
  transition: transform 0.25s ease, opacity 0.24s ease;

  &:before {
    content: "";
    background-color: $input-bg;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    transform: rotate(45deg);
    top: -10px;
    right: 3.625rem;
  }

  .theme-select-inner {
    padding: map-get($spacers, 4);
    opacity: 0;
    transition: opacity 0.15s ease;

    .theme-select-theme {
      position: relative;
      min-height: 90px;

      .theme-select-title {
        position: absolute;
        height: 100%;
        width: calc(100% - var(--bs-gutter-x) * 1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
      }

      .theme-select-title-inner {
        font-family: $headings-font-family;
        font-size: $h4-font-size;
        font-weight: 700;
      }

      .theme-select-img {
        border-radius: $border-radius;
        background-color: rgba(0, 0, 0, 1);
        box-shadow: $box-shadow;

        img {
          height: 90px;
          width: 100%;
          object-fit: cover;
          border-radius: $border-radius;
          transition: opacity 0.25s ease;
          opacity: 0.4;
        }
      }

      &:hover {
        cursor: pointer;

        .theme-select-img {
          img {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &.show {
    transform: scaleY(1);
    opacity: 1;

    .theme-select-inner {
      opacity: 1;
    }
  }
}

.toggle-theme-select-wrapper {
  width: 45px;
  height: 45px;
  position: relative;
  animation: fadeIn .25s ease forwards;
  float: right;

  .toggle-theme-select {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    height: 100%;
    background-color: $input-bg;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.25s ease;

    &:hover {
      cursor: pointer;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
    }
  }

  .remove-theme-filter-image {
    position: absolute;
    right: -5px;
    top: -5px;
    opacity: 0;
    transform: scale(1);
    text-shadow: 0 6px 10px rgba(0, 0, 0, 0.0);
    transition: text-shadow 0.25s ease, transform 0.25s ease;


    &:hover {
      cursor: pointer;
      text-shadow: 0 6px 10px rgba(0, 0, 0, 0.6);
      transform: scale(1.2);
    }
  }

  &.false {
    img {
      transform: translateY(2px);
    }
  }

  &.true {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .remove-theme-filter-image {
      opacity: 1;
    }
  }
}

.close-theme-select {
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  color: rgb(114, 114, 125);
  font-size: 1.25rem;

  &:hover {
    cursor: pointer;
  }
}