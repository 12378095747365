.header-wrapper {
  background-color: rgba(22, 22, 26, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 100%;
  transition: width 0.25s ease, transform 0.25s ease;
  transform: translateX(0);
  position: fixed;
  z-index: 48;

  .header {
    padding: map-get($spacers, 2) 0;
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-toggle {
    .burger {
      cursor: pointer;
      height: 48px;
      position: relative;
      transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
      user-select: none;
      width: 48px;
      outline: none;
      transform: none;
    }

    &.active {
      .burger {
        transform: rotate(90deg);
      }
    }

    .burger-line {
      background: rgb(255, 255, 255);
      height: 3px;
      left: 6px;
      position: absolute;
      width: 36px;
      top: 12px;
      transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
      transform: none;

      &.burger-line-2 {
        top: 23px;
      }

      &.burger-line-3 {
        top: 34px;
      }
    }

    &.active {
      .burger-line {
        &.burger-line-1 {
          transform: rotate(45deg) translate(7.77px, 7.77px);
        }

        &.burger-line-2 {
          transform: scaleX(0);
        }

        &.burger-line-3 {
          transform: rotate(-45deg) translate(7.77px, -7.77px);
        }
      }
    }
  }
}