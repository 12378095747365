.img-loader {
  height: 100%;
  min-width: 100%;
  background: linear-gradient(243deg, #0c0c10, #36363f);
  background-size: 400% 400%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  -webkit-animation: gradientAnimation 8s ease infinite;
  -moz-animation: gradientAnimation 8s ease infinite;
  animation: gradientAnimation 8s ease infinite;
}

.loader {
  width: 100%;
  grid-column: 1/3;
  text-align: center;
}