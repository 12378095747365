/* didact-gothic-regular - latin */
@font-face {
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/didact-gothic/didact-gothic-v19-latin-regular.svg#DidactGothic') format('svg'); /* Legacy iOS */
  font-display: swap;
}