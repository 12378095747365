.btn-outline-primary,
.btn-outline-primary:hover {
  color: $btn-color;
  border-color: $btn-border-color;
  border-radius: $btn-border-radius;
  background-color: transparent;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease;

  @media screen and (max-width: map-get($grid-breakpoints, sm)) {
    & {
      padding: 0.8rem 1rem;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: $btn-hover-bg;
      border-color: $btn-hover-bg;
      color: $dark;
    }
  }
}

.btn-secondary {
  color: $dark;
  border-color: $light;
  background-color: $light;
  font-weight: 700;
  filter: brightness(1);
  transition: filter 0.25s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: map-get($grid-breakpoints, sm)) {
    & {
      padding: 0.8rem 1rem;
    }
  }

  @media (hover: hover) {
    &:hover {
      filter: brightness(0.85);
    }
  }
}

.scroll-top-button {
  background-color: $card-bg;
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 60;
  right: 1.2rem;
  bottom: 1.2rem;
  transform: rotate(90deg);
  border-radius: 50%;
  color: $light;
  border: 1px solid $light;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  font-size: 0.9rem;
  transition: opacity 0.25s ease;

  &.show {
    opacity: 0.5;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}