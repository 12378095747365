// colors
$primary: rgb(20, 20, 23);
$secondary: rgb(255, 255, 255);
$base-bg: RGB(14, 14, 15);
$light: rgb(255, 255, 255);
$dark: rgb(0, 0, 0);

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark
);

// font basics
$font-family-base: 'Didact Gothic';
$font-size-base: 1.125rem;
$base-font-color: rgb(255, 255, 255);

// font headings
$headings-font-family: 'Source Serif Pro';
$headings-font-weight: 700;
$headings-color: $light;
$h1-font-size: $font-size-base * 2.7778;
$h2-font-size: $font-size-base * 2.33;
$h3-font-size: $font-size-base * 1.78;
$headings-margin-bottom: $spacer * 1;

// container
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 912px
);

// breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 540px,
        md: 720px,
        lg: 912px,
        xl: 1200px,
        xxl: 1400px
);

// border
$border-radius: 12px;

// shadows
$box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);

// spacing
$spacers: (
        0: 0,
        1: $spacer * 0.5,
        2: $spacer * 1,
        3: $spacer * 1.5,
        4: $spacer * 2,
        5: $spacer * 2.5,
        6: $spacer * 3,
        7: $spacer * 3.5,
        8: $spacer * 4,
);

// header
$header-bg: rgba(22, 22, 26, 1);

// navigation
$navigation-bg: $primary;
$navigation-font-color: rgb(160, 160, 160);
$navigation-active-font-color: $base-font-color;
$navigation-font-family: 'Source Sans Pro';
$navigation-width: 290px;
$navigation-box-shadow: 4px 0 10px 0 rgba(0, 0, 0, 0.5);
$navigation-border: rgb(49, 49, 51);

// shortcut navigation
$shortcut-item-font-size: 1.75rem;
$shortcut-item-font-family: $headings-font-family;

// cards
$card-bg: rgba(22, 22, 26, 0.55);
$card-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
$card-border-radius: $border-radius;
$card-color: rgb(160, 160, 160);
$card-title-color: $base-font-color;

// buttons
$btn-font-family: 'Source Sans Pro';
$btn-border-color: rgba(125, 125, 125, 0.7);
$btn-color: rgba(160, 160, 160);
$btn-hover-bg: rgba(255, 255, 255, 0.95);
$btn-padding-x: 2.334rem;
$btn-padding-y: 0.8rem;
$btn-box-shadow: 0 8px 20px rgba(0, 4, 13, 0.2);
$btn-border-radius: $border-radius;
$btn-border-width: 2px;
$btn-font-size: 1.125rem;

// Paragraphs
$paragraph-margin-bottom: 1.8rem;

// links
$link-color: rgb(255, 255, 255);
$link-color-hover: rgb(160, 160, 160);
$link-font: 'Source Sans Pro';

// footer
$footer-bg: $primary;
$footer-height: 80px;
$footer-link-color: rgb(160, 160, 160);
$footer-link-color-hover: $light;

// forms
$form-select-color: rgb(212, 212, 212);
$input-border-color: rgba(212, 212, 212, 1);
$input-bg: rgba(35, 35, 38);
$input-color: rgb(147, 150, 153);
$input-group-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
$form-select-bg: transparent;
$form-select-border-radius: 10px;
$form-select-padding-x: 0.7778rem;
$form-select-indicator: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><title>triangle-down</title><g fill="#ffffff"><path d="M11.5,2H.5a.5.5,0,0,0-.412.783l5.5,8a.5.5,0,0,0,.824,0l5.5-8A.5.5,0,0,0,11.5,2Z" fill="#ffffff"></path></g></svg>');
$form-select-focus-box-shadow: none;
$form-select-focus-border-color: $input-border-color;

// intro
$intro-text-color: rgb(195, 195, 195);

// stilfinder
$quiz-select-color: rgb(93, 93, 93);
$quiz-footer-color: $quiz-select-color;