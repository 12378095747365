/* source-serif-pro-300 - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-serif-pro-300italic - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 300;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-300italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-serif-pro-regular - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-serif-pro-italic - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-serif-pro-700 - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-serif-pro-700italic - latin-ext_latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/source-serif-pro/source-serif-pro-v15-latin-ext_latin-700italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}