.card {
  background-color: $card-bg;
  backdrop-filter: blur(10px);
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  overflow: hidden;
  height: 100%;
  color: $card-color;
  animation: fadeIn .5s ease;

  .card-title {
    color: $card-title-color;
  }

  .card-content {
    padding: map-get($spacers, 4) map-get($spacers, 4);
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
  }
}