.gallery {
  margin-bottom: map-get($spacers, 8);

  .gallery-item {
    background-color: $card-bg;
    backdrop-filter: blur(10px);
    border-radius: $border-radius;
    overflow: hidden;
    box-shadow: $card-box-shadow;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    & {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;

      .gallery-item {
        &:first-child {
          grid-column: 1/3;
          grid-row: 1/3;
        }
      }
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    & {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;

      .gallery-item {
        &:first-child {
          grid-column: 1/3;
          grid-row: 1/3;
        }
      }
    }
  }
}