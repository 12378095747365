.theme {
  position: relative;
}

/*Group themeList*/
.theme-list {
  .card-content {
    padding: map-get($spacers, 4);
  }

  .theme-list-item-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: map-get($spacers, 2);

    .theme-list-item-title {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }

    .theme-item-colors {
      display: flex;
      grid-gap: 5px;

      .theme-item-color {
        border-radius: 50%;
        width: 1.15625rem;
        aspect-ratio: 1/1;
      }
    }
  }

  .card-img {
    img {
      aspect-ratio: 3/2;
      object-fit: cover;
      width: 100%;
    }

    video {
      width: 100%;
      height: auto;
    }
  }
}

/*end themeList*/

/*Group themeNav*/
.theme-nav {
  .theme-nav-item {
    border-radius: $border-radius;
    aspect-ratio: 2/1;
    overflow: hidden;
    position: relative;
    box-shadow: $card-box-shadow;
    margin-bottom: map-get($spacers, 2);
    background-color: $black;

    a {
      display: block;
      position: relative;
      height: 100%;
      color: $base-font-color;
      border-radius: $border-radius;
      overflow: hidden;
    }

    .theme-nav-item-title {
      position: absolute;
      font-weight: 700;
      z-index: 20;
      height: 100%;
      width: 100%;
      display: flex;
      font-size: $shortcut-item-font-size;
      font-family: $shortcut-item-font-family;
      align-items: center;
      justify-content: center;
    }

    .theme-nav-item-img {
      border-radius: $border-radius;
      height: 100%;
      line-height: 0;

      img, video {
        border-radius: $border-radius;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        object-fit: cover;
        transition: opacity 0.25s ease;
      }
    }

    &.active {
      img, video {
        opacity: 1;
      }

      .main-nav-name {
        text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
      }
    }

    @media (hover: hover) {
      &:hover {
        img, video {
          opacity: 0.6;
        }
      }
    }
  }
}

/*end themeNav*/