#overlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  opacity: 0;
  transition: opacity 0.5s ease;

  &.show,
  &.active {
    display: block;
    opacity: 1;
  }

  &.active {
    display: block;
    z-index: 45;
  }
}