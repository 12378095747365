body {
  background-color: $base-bg;
}

.app-page-wrapper {
  margin-bottom: -$footer-height;
  min-height: 100vh;
  padding-bottom: map-get($spacers, 5);
}

.content-inner {
  margin-bottom: 5rem;
}

img {
  max-width: 100%;
  height: auto;
}

.infinite-scroll-component {
  padding-bottom: 1rem;
}