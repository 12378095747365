.navigation {
  top: 0;
  background-color: $navigation-bg;
  color: $navigation-font-color;
  width: $navigation-width;
  position: fixed;
  height: 100%;
  padding: map-get($spacers, 6) map-get($spacers, 4);
  box-shadow: $navigation-box-shadow;
  z-index: 20;
  overflow-y: auto;
  font-family: $navigation-font-family;
  transform: translateX(0);
  transition: transform 0.25s ease;

  @media screen and (max-width: map-get($grid-breakpoints, xl)) {
    transform: translateX(-$navigation-width);
    z-index: 50;
    box-shadow: none;
  }

  a {
    color: $navigation-font-color;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: color 0.25s ease;

    @media (hover: hover) {
      &:hover {
        color: $light;
      }
    }

    .icon {
      font-size: 1.375rem;
      margin-right: 1rem;
    }
  }

  .main-navigation {
    border-bottom: 1px solid $navigation-border;
    margin-bottom: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
    padding-top: map-get($spacers, 2);

    .main-nav-item {
      margin-bottom: map-get($spacers, 2);

      .active {
        color: $navigation-active-font-color;
      }
    }

    .logo {
      display: block;
      text-align: center;
      margin-bottom: map-get($spacers, 6);
    }
  }

  .shortcuts-title {
    text-transform: uppercase;
    margin-bottom: map-get($spacers, 3);
    font-weight: 700;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $navigation-bg;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $navigation-bg;
  }
}

.active {
  .navigation {
    transform: translateX(0);
    box-shadow: $navigation-box-shadow;
  }
}