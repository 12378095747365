.link {
  text-decoration: none;
  color: $link-color;
  font-family: $link-font;
  transition: color .25s ease;

  @media (hover: hover) {
    &:hover {
      color: $link-color-hover;
    }
  }

  &.back-link {
    color: $light;
    display: inline-flex;
    align-items: center;
    grid-gap: 8px;

    .icon {
      transform: translateX(0);
      transition: transform 0.25s ease;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        color: $intro-text-color;

        .icon {
          transform: translateX(3px);
        }
      }
    }
  }
}