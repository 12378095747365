.content-wrapper {
  padding-top: map-get($spacers, 8);
  padding-bottom: map-get($spacers, 8);

  @media screen and (max-width: map-get($grid-breakpoints, xl)) {
    padding-top: 9rem;
    padding-bottom: map-get($spacers, 8);
  }
}

.content {
  @media screen and (min-width: map-get($grid-breakpoints, xl)) {
    & {
      padding-left: $navigation-width;
    }
  }
}