@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeBlurOut {
  0% {
    opacity: 1;
    color: transparent;
    text-shadow: 0 0 1px #fff;
  }
  100% {
    text-shadow: 0 0 20px #fff;
    opacity: 0;
  }
}

@keyframes fadeBlurIn {
  0% {
    opacity: 0;
    color: transparent;
    text-shadow: 0 0 20px #fff;
  }
  100% {
    text-shadow: none;
    color: $base-font-color;
    opacity: 1;
  }
}

@-webkit-keyframes gradientAnimation {
  0% {
    background-position: 77% 0
  }
  50% {
    background-position: 24% 100%
  }
  100% {
    background-position: 77% 0
  }
}

@-moz-keyframes gradientAnimation {
  0% {
    background-position: 77% 0
  }
  50% {
    background-position: 24% 100%
  }
  100% {
    background-position: 77% 0
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 77% 0
  }
  50% {
    background-position: 24% 100%
  }
  100% {
    background-position: 77% 0
  }
}