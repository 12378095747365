.material {
  position: relative;
}

.materials {
  margin-bottom: map-get($spacers, 5);
  overflow: visible !important;

  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    & {
      grid-template-columns: 1fr 1fr;
      grid-gap: map-get($spacers, 5);
      display: grid;
    }
  }

  &.materials-not-found {
    color: $light;
    justify-content: center;

    & > div {
      grid-column: 1/3;
    }
  }

  .card {
    background-color: $card-bg;
    box-shadow: $card-box-shadow;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: map-get($spacers, 5);

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      & {
        margin-bottom: 0;
      }
    }

    .material-teaser {
      padding: map-get($spacers, 4);
    }

    .material-img {
      width: 100%;

      img {
        width: 100%;
        aspect-ratio: 4/3;
        object-fit: contain;
        object-position: center bottom;
      }
    }

    .material-tags {
      display: flex;

      .material-tag {
        margin-left: 5px;

        img {
          border-radius: 50%;
          width: 32px;
          aspect-ratio: 1/1;
        }
      }
    }
  }
}