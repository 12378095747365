.hero-img {
  width: 100%;
  height: 80vh;
  position: fixed;
  z-index: -10;
  top: 0;
  left: 0;
  animation: linear fadeIn .25s;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    width: 100%;
    height: 35%;
    background: $base-bg;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $base-bg 100%);
    z-index: 1;
    position: absolute;
    bottom: -2px;
    left: 0;
  }


}